import request from '../utils/request';
import UrlUtil from '../utils/url-config';

const API = UrlUtil.getAPI();
//商品模块接口
export const ShopFetch = {
    previewProductData: (params, success) => {
        return request('BODY', `${API}/v1/shop/goods/previewProductData`, params, success)
    },
    exportOrderGoodsList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/exportOrderGoodsList`, params, success)
    },
    //分页获取首页公告列表
    searchNoticeList: (params, success) => {
        return request('GET', `${API}/v1/shop/home/noAuth/searchNoticeList`, params, success)
    },
    //获取公告详情
    getNoticeById: (params, success) => {
        return request('GET', `${API}/v1/shop/home/noAuth/getNoticeById/${params}`, params, success)
    },
    //获取商品详情
    getGoodsById: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getGoodsById/` + params, params, success)
    },
    //商城商品分页查询
    searchListForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchListForShop`, params, success)
    },
    //web端查询收藏商品
    searchFavoriteGoodsForWeb: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/searchFavoriteGoodsForWeb`, params, success)
    },
    //web端收藏商品分类筛选
    searchTypeByFavorite: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/searchTypeByFavorite`, params, success)
    },
    //web端查询常购商品清单
    searchClientGoodsForWeb: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/searchClientGoodsForWeb`, params, success)
    },
    //web端常购商品分类筛选
    searchTypeByClientGoods: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/searchTypeByClientGoods`, params, success)
    },
    //获取指定公司的商品分类树
    getUsePropertyTree: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getUsePropertyTree`, params, success)
    },
    addGoodsFavorite: (_params, _success, _error) => {
        request('POST', `${API}/v1/shop/goodsFavorite/addGoodsFavorite`, _params, _success, _error)
    },
    deleteGoodsFavorite: (_params, _success, _error) => {
        request('POST', `${API}/v1/shop/goodsFavorite/deleteGoodsFavorite`, _params, _success, _error)
    },
    //移出收藏夹
    batchDeleteGoodsFavorite: (params, success) => {
        return request('POST', `${API}/v1/shop/goodsFavorite/batchDeleteGoodsFavorite`, params, success)
    },
    //列出企业所有商品品牌
    listBrandByCompany: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/listBrandByCompany`, params, success)
    },
    //列出企业所有商品分类
    listTypeByCompany: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getTypeTree`, params, success)
    },
    //列出企业所有商品标签
    searchTagList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/listTagByCompany`, params, success)
    },
    //获取商品设置信息
    getGoodsSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/goodsSetting/getByCompanyId/${params}`, params, success)
    },
    //获取销售设置信息
    getSaleSetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getSaleSetting/${params}`, params, success)
    },
    //获取商城开放注册应用设置
    getShopRegisterSetting: (params, success) => {
        return request('GET', `${API}/v1/shopRegisterSetting/noAuth/getByCompany`, params, success)
    },
    //获取企业信息
    getCompany: (params, success) => {
        return request('GET', `${API}/v1/shop/home/noAuth/getCompany/${params}`, params, success)
    },
    // 获取装修信息
    getPageDataByPc: (params, success) => {
        return request('GET', `${API}/v1/shop/decorate/noAuth/getPageDataByPc`, params, success)
    },
    // 获取装修信息
    getPcTemplateById: (params, success) => {
        return request('GET', `${API}/v1/shop/decorate/getPcTemplateById/${params}`, params, success)
    },
    // 获取店铺装修商品
    searchListForDecorate: (params, success) => {
        return request('GET', `${API}/v1/shop/decorate/noAuth/searchListForDecorate`, params, success)
    },
    // 获取店铺装修商品
    countDecorateVisitor: (params, success) => {
        return request('POST', `${API}/v1/decorate/wpShopTemplate/noAuth/countVisitor/${params}`, params, success)
    },
    // 获取店铺装修促销
    listForGoodsPromotionActivityIds: (params, success) => {
        return request('GET', `${API}/v1/shop/decorate/noAuth/listForGoodsPromotionActivityIds`, params, success)
    },
    // //获取运费计算设置
    // getShipFeeSettingByLogin: (params, success) => {
    //     return request('GET', `${API}/v1/setting/shipFeeSetting/getByLoginUser/${params}`, params, success)
    // },
    //获取运费计算设置
    getShipFeeSettingByLogin: (params, success) => {
        return request('GET', `${API}/v1/setting/shipFeeSetting/getAllShipFeeSetting/${params}`, params, success)
    },
    //获取预存款明细
    searchFinanceDetail: (params, success) => {
        return request('GET', `${API}/v1/shop/order/searchPayLog`, params, success)
    },
    //记录浏览商品足迹
    saveGoodsViewLog: (params, success) => {
        return request('POST', `${API}/v1/goods/goodsViewLog/save`, params, success)
    },
    //获取推荐商品
    searchRecommendedGoodsListForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchRecommendedGoodsListForShop`, params, success)
    },
    // 根据分类查询关联品牌
    searchBrandList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/listBrandByCompany`, params, success)
    },
    // 查询套餐列表数据
    searchGoodsPackageList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchGoodsPackageListForShop`, params, success)
    },
    //获取商品优惠信息
    getGoodsPackageInfoById: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/getGoodsPackageByIdForWeb/${params}`, params, success)
    },
};
//购物车模块接口
export const CartFetch = {
    //获取合并订单
    combinedPayGetById: (params, success) => {
        request('GET', `${API}/v1/finance/combinedPay/getById/${params}`, params, success)
    },
    //合并订单
    combinedPaySave: (params, success) => {
        return request('POST', `${API}/v1/finance/combinedPay/save`, {jsonStr: JSON.stringify(params)}, success)
    },
    // 保存/修改商城购物车列表
    batchModify: (params, success) => {
        return request('POST', `${API}/v1/shop/cart/updateForWeb`, {jsonStr: JSON.stringify(params)}, success)
    },
     // 保存/修改商城购物车列表
    newBatchModify: (params, success) => {
        return request('BODY', `${API}/v1/shop/cart/batchModify`, params, success)
    },
    //获取购物车列表
    searchList: (params, success) => {
        return request('GET', `${API}/v1/shop/cart/searchList`, params, success)
    },
    //webapp再来一单添加商品合并到购物车
    mergeCartForWeb: (_params, _success, _error) => {
        return request('POST', `${API}/v1/shop/cart/mergeCartForWeb`, _params, _success, _error)
    },
    //购物车查询
    searchListForShopCart: (params, success) => {
        return request('GET', `${API}/v1/shop/cart/searchListForShopCart`, params, success)
    },
    //删除商城购物车
    batchDelete: (params, success) => {
        return request('POST', `${API}/v1/shop/cart/batchDelete`, params, success)
    },
    //获取商品优惠信息
    getPromotionByGoodsId: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/getPromotionByGoodsId`, params, success)
    },
    //根据规格ids串获取商品优惠信息
    getPromotionByProductIds: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/getPromotionByProductIds`, params, success)
    },
    //获取商品优惠信息
    getActivityById: (params, success) => {
        return request('GET', `${API}/v1/sale/goodsPromotion/getActivityById/${params}`, null, success)
    },
    //获取订单优惠信息
    getPromotionByCompanyId: (params, success) => {
        return request('GET', `${API}/v1/sale/orderPromotion/getPromotionByCompanyId`, params, success)
    },
    //获取购物车SKU总数量
    countNumber: (params, success) => {
        return request('GET', `${API}/v1/shop/cart/countNumber`, params, success)
    },
    //批量添加商城商品收藏
    batchAddGoodsFavorite: (params, success) => {
        return request('POST', `${API}/v1/shop/goodsFavorite/batchAddGoodsFavorite`, params, success)
    },
    //获取商品预售信息
    getpresalePromotionByGoodsId: (params, success) => {
        return request('GET', `${API}/v1/sale/presalePromotion/getPromotionByGoodsId`, params, success)
    },
    //获取商品预售信息
    getPresalePromotionByProductIds: (_params, _success, _error) => {
        return request('GET', `${API}/v1/sale/presalePromotion/getPromotionByProductIds`, _params, _success, _error)
    },
    //保存订单
    save: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/save`, params, success)
    },
    //保存预售订单
    presaleSave: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/presaleSave`, params, success)
    },
};
//商城订单
export const OrderFetch = {
    //保存/修改商城购物车列表
    getById: (params, success) => {
        return request('GET', `${API}/v1/shop/order/getById/` + params, params, success)
    },
    //订单支付(线下或预付款)
    orderPay: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/orderPayByOfflineOrPre`, params, success)
    },
    //获取订单列表
    searchListForShop: (params, success) => {
        return request('GET', `${API}/v1/shop/order/searchListForShop`, params, success)
    },
    //取消订单
    orderCancel: (params, success) => {
        return request('POST', `${API}/v1/shop/order/cancel`, params, success)
    },
    //删除订单
    markNotViewForShop: (params, success) => {
        return request('DELETE', `${API}/v1/shop/order/markNotViewForShop/${params}`, params, success)
    },
    //确认收货
    receiveBySaleOrder: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/receive`, params, success)
    },
    //确认收货
    receiveById: (params, success) => {
        request('GET', `${API}/v1/shop/order/batchReceive/${params}`, params, success)
    },
    //确认收货
    receive: (params, success) => {
        return request('BODY', `${API}/v1/shop/order/receive`, params, success)
    },
    // 根据快递单号获取物流信息
    getLogisticInfo: (params, success) => {
        return request('GET', `${API}/v1/common/helper/getLogisticInfo`, params, success)
    },
    // 获取企业线下收款账号
    listOfflineAccount: (params, success) => {
        return request('GET', `${API}/v1/shop/order/listOfflineAccount`, params, success)
    },
    // 分页获取商城订单消息列表
    searchOrderMessage: (params, success) => {
        return request('GET', `${API}/v1/shop/orderMessage/searchListByLogin`, params, success)
    },
    // 标记订单消息为已读
    markRead: (params, success) => {
        return request('POST', `${API}/v1/shop/orderMessage/markRead/${params}`, null, success);
    },
    // 添加日志
    saveOrderLog: (_params, _success, _error) => {
        return request('BODY', `${API}/v1/shop/order/saveOrderLog`, _params, _success, _error)
    },
};
//系统模块
export const SystemFetch = {
    // 取当前登录人关联的所有账号信息列表
    getByHost(params) {
        return request(`GET`, `${API}/v1/base/company/noAuth/getByHost`, params);
    },
    //订货端获取企业设置
    getShopCompanySetting: (params, success) => {
        return request('GET', `${API}/v1/setting/setting/getShopCompanySetting/${params}`, params, success)
    },
    //管理端-体验
    experience: (params, success) => {
        return request('POST', `${API}/v1/base/experience/toPcMgr`, params, success)
    },
    //订货端-体验
    shopExperience: (params, success) => {
        return request('POST', `${API}/v1/account/getExperienceUser`, {...params, type_: 2}, success)
    },
    //获取登陆用户信息
    getClientById: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getClientById/${params}`, params, success)
    },
    //获取地址
    listAll: (params, success) => {
        return request('GET', `${API}/v1/shop/address/listByLoginUser`, params, success)
    },
    //获取地址
    deleteAddress: (params, success) => {
        return request('DELETE', `${API}/v1/shop/address/deleteById/${params}`, params, success)
    },
    //保存、修改地址
    saveAddress: (params, success) => {
        return request('BODY', `${API}/v1/shop/address/save`, params, success)
    },
    //设置默认地址
    getUserInfo: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getShopUserInfo`, params, success)
    },
    // 保存用户客户信息
    saveUserClientInfo: (params, success) => {
        return request('POST', `${API}/v1/shop/account/saveUserClientInfo`, params, success)
    },
    // 获取文件上传的token信息
    getUploadToken: (params, success) => {
        return request('GET', `${API}/v1/common/upload/getUploadToken`, params, success)
    },
    //获取当前登录用户的authCode
    getAuthCode: (params, success) => {
        return request('GET', `${API}/v1/account/getAuthCode`, params, success)
    },
    //获取省市县
    buildSimpleRegionTree: (params, success) => {
        return request('GET', `${API}/v1/common/helper/buildSimpleRegionTree`, params, success)
    },
    // 获取街道
    listStreetById: (params, success) => {
        return request('GET', `${API}/v1/common/helper/listStreetById/${params}`, params, success)
    },
    //获取微信jsticket
    getWxTicket: (params, success) => {
        return request('GET', `${API}/v1/shop/account/noAuth/getWxTicket`, params, success)
    },
    // 搜索框  获取焦点 未输入搜索关键词前 =》 查出客户搜索历史关键词5个
    searchClientKeyWordList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchClientKeyWordList`, params, success)
    },
    // 关键词搜索
    searchKeyWordList: (params, success) => {
        return request('GET', `${API}/v1/shop/goods/noAuth/searchKeyWordList`, params, success)
    },
    // 删除客户指定的搜索关键词
    toDeleteKeywordsById: (params, success) => {
        return request('GET', `${API}/v1/goods/goodsSearchLog/deleteById/${params}`, params, success)
    },
    // 删除客户所有的搜索关键词
    toDeleteClientAllKeywords: (params, success) => {
        return request('GET', `${API}/v1/goods/goodsSearchLog/toDeleteClientAllKeywords`, params, success)
    },
    // 个人中心
    getShopUserInfoForHome: (params, success) => {
        return request('GET', `${API}/v1/shop/account/getShopUserInfoForHome`, params, success)
    },
    // 获取商城帮助
    shopHelpTypeSearchList: (params, success) => {
        return request('GET', `${API}/v1/shopHelp/shopHelpType/noAuth/searchListForShop`, params, success)
    },
    // 获取微信用户信息
    getWeChatUserInfo: (params, success) => {
        return request('GET', `${API}/v1/setting/weChatSetting/noAuth/getWeChatUserInfo`, params, success)
    },
    // 微信openId登录
    openIdLogin: (params, success) => {
        return request('POST', `${API}/v1/account/openIdLogin`, params, success)
    },
    // 绑定微信openId
    bindOpenId: (params, success) => {
        return request('POST', `${API}/v1/account/bindOpenId`, params, success)
    },
    //获取应用状态
    getApplicationStatus: (params, success) => {
        return request('GET', `${API}/v1/application/noAuth/getApplicationStatus`, params, success)
    },
    //
    getSubsidiaryById: (params, success) => {
        return request('GET', `${API}/v1/shop/subsidiary/getById/${params}`, params, success)
    },
    searchExperienceShopList: (params, success) => {
        return request('GET', `${API}/v1/store/experienceShop/searchList`, params, success)
    },
    getExperienceShopById: (params, success) => {
        return request('GET', `${API}/v1/store/experienceShop/getById/${params}`, params, success)
    },
    getOpenAuthCode: (params, success) => {
        return request('GET', `${API}/openapi/v1/auth/getAuthCode`, params, success)
    },
    // 我的发票列表
    searchInvoiceList: (params, success) => {
        return request('GET', `${API}/v1/invoice/invoiceLog/searchList`, params, success)
    },
    // 待开发票列表
    searchOrderInvoiceCountList: (params, success) => {
        return request('GET', `${API}/v1/invoice/invoiceLog/searchOrderInvoiceCountList`, params, success)
    },

    // 订单发票详情
    getInvoiceById: (params, success) => {
        return request('GET', `${API}/v1/invoice/invoiceLog/getById/${params}`, params, success)
    },

};
//支付模块接口
export const PayFetch = {
    //合并付款预存款和线下付
    combinedPayByOfflineOrPre: (params, success) => {
        return request('POST', `${API}/v1/finance/combinedPay/combinedPayByOfflineOrPre`, params, success)
    },
    //构建支付宝支付参数
    buildWebAlipayApi: (params, success) => {
        return request('BODY', `${API}/v1/shop/pay/buildWebAlipayApi`, params, success)
    },
    // 构建订单合并支付支付宝支付请求
    buildCombinedPayWebAlipayApi: (params, success) => {
        return request('POST', `${API}/v1/shop/pay/buildCombinedPayWebAlipayApi`, params, success)
    },
    //获取微信支付参数
    buildWeixinScanpayApi: (orderId, params, success) => {
        return request('BODY', `${API}/v1/shop/pay/buildWeixinScanpayApi/${orderId}${params}`, params, success)
    },
    //获取微信支付参数
    buildCombinedWeixinScanpayApi: (orderId, params, success) => {
        return request('POST', `${API}/v1/shop/pay/buildCombinedWeixinScanpayApi/${orderId}${params}`, params, success)
    },
    //获取订单财务状态
    getFinanceStatus: (params, success) => {
        return request('GET', `${API}/v1/shop/order/getFinanceStatus/${params}`, params, success)
    },
    // 组合支付
    combinationPay: (params, _success, _error) => {
        return request('BODY', `${API}/v1/shop/order/combinationPay`, params, _success, _error)
    },
    // 易生聚合支付配置
    getYiShengPayConfig: (params, _success, _error) => {
        return request('GET', `${API}/v1/finance/aggregatePayConfig/getByType/1`, null, _success, _error)
    },
    // 易生扫码支付
    yiShengScanPay: (params, _success, _error) => {
        return request('POST', `${API}/v1/yiShengPay/apply/scanPay`, params, _success, _error)
    },
    // 京东支付
    jdOrderPcCashier: (params, _success, _error) => {
        return request('BODY', `${API}/v1/jdPay/apply/orderPcCashier`, params, _success, _error)
    },
    // mercadoPago支付
    buildMercadoPagoQRApi: (params, success) => {
        return request('GET', `${API}/v1/mercadoPago/pay/buildMercadoPagoQRApi`, params, success)
    },
    //查询是否开通产品类别
    checkPayType: (params, success) => {
        return request('GET', `${API}/v1/jdPay/merchant/checkPayType`, params, success)
    },
};
// 退货
export const SaleBackFetch = {
    // 申请退货
    save: (params, success) => {
        return request('BODY', `${API}/v1/sale/saleBack/save`, params, success)
    },
    // 退货列表
    searchList: (params, success) => {
        return request('GET', `${API}/v1/sale/saleBack/searchList`, params, success)
    },
    // 退货列表
    getById: (params, success) => {
        return request('GET', `${API}/v1/sale/saleBack/getById/${params}`, params, success)
    },
};
// 免费领
export const FreeGoodsFetch = {
    //购物车查看对应客户类型可参与的免费活动信息
    freeActivityByClientType: (params, success) => {
        return request('GET', `${API}/v1/sl/freeGoodsActivity/freeActivityByClientType`, params, success)
    },
};
// 串号查询
export const GoodsSnFetch = {
    //查询指定企业串号查询设置, 企业id为空时,企业id=登录用户企业
    getSearchSetting: (params, success) => {
        return request('GET', `${API}/v1/house/goodsSnSearchSetting/noAuth/getSearchSetting`, params, success)
    },
    //精确查询串号信息, 企业id为空时,企业id=登录用户企业
    getByCode: (params, success) => {
        return request('GET', `${API}/v1/house/goodsSn/noAuth/getByCode`, params, success)
    },
};
// 自提点
export const PickUpStationFetch = {
    //分页查询自提点
    searchList: (params, success) => {
        return request('GET', `${API}/v1/house/selfPickSite/searchListForShop`, params, success)
    },
    //客户默认自提点
    getClientDefaultSite: (params, success) => {
        request('POST', `${API}/v1/house/selfPickSite/getClientDefaultSite/${params}`, params, success)
    },
};
export const CouponFetch = {
    //获取客户优惠券
    listCouponByShop: (params, success) => {
        return request('GET', `${API}/v1/shop/coupon/listCouponByShop`, params, success)
    },
    //获取客户相关优惠券活动
    listCouponActivityByShop: (params, success) => {
        return request('GET', `${API}/v1/shop/coupon/listCouponActivityByShop`, params, success)
    },
    //领取优惠券
    receiveCoupon: (params, success) => {
        return request('POST', `${API}/v1/shop/coupon/receiveCoupon`, params, success)
    },
    //批量领取优惠券
    batchReceiveCoupon: (params, success) => {
        return request('POST', `${API}/v1/shop/coupon/batchReceiveCoupon`, params, success)
    },
};

export const EnquiryFetch = {
      //删除搜索记录
      deleteSearchVinLongById: (params, success) => {
        return request('DELETE', `${API}/v1/client/client/deleteSearchVinLongById/${params}`, params, success)
    },
    // 智能识别
    batchSearchKeywordByInquirySheet : (params, success) => {
      return request('BODY', `${API}/v1/epc/batchSearchKeywordByInquirySheet`, params, success)
    },
    preGetInquirySheetResult : (params, success) => {
      return request('BODY', `${API}/v1/inquiry/inquirySheet/preGetInquirySheetResult`, params, success)
    },
    getGoodsByProductGroupIdOrOeNumber : (params, success) => {
      return request('BODY', `${API}/v1/inquiry/inquirySheet/getGoodsByProductGroupIdOrOeNumber`, params, success)
    },
    searchKeywordByInquirySheet: (params, success, error) => {
      return request('GET', `${API}/v1/epc/searchKeywordByInquirySheet`, params, success, error)
    },
    imageRecognitionForVin : (params, success) => {
      return request('BODY', `${API}/v1/etopLive/imageRecognitionForVin`, params, success)
    },
    getMainEpcImgDetailAndImgList : (params, success) => {
      return request('BODY', `${API}/v1/zpy/getMainEpcImgDetailAndImgList`, params, success)
    },
    //根据Vin码搜索车版本
    getByVin: (params, success) => {
      return request('GET', `${API}/v1/car/vin`, params, success)
    },
    // 汽车车版本搜索
    getAcCarList: (params, success) => {
        return request('GET', `${API}/v1/car/getAcCarList`, params, success);
    },
    // 汽车车版本搜索--分页
    searchAcCarList: (params, success) => {
        return request('GET', `${API}/v1/car/searchList`, params, success);
    },
    // 根据OE码+车品牌 查询OE码关联的对应排量
    getAcDisplacementNameList: (params, success) => {
        return request('GET', `${API}/v1/car/noAuth/getAcDisplacementNameList`, params, success);
    },
    // 根据OE码+车品牌 查询OE码关联的对应年份
    getVehicleOfYearsList: (params, success) => {
        return request('GET', `${API}/v1/car/noAuth/getVehicleOfYearsList`, params, success);
    },
    // 配置信息
    getCarConfigById: (params, success, error) => {
        return request('GET', `${API}/v1/car/getCarById/${params}`, params, success, error)
    },
    //分页获取产品组列表
    searchListForShopOld: (params, success) => {
      return request('GET', `${API}/v1/accessories/productGroup/noAuth/searchListForShop`, params, success)
    },
    //分页获取产品组列表
    searchListForShop: (params, success) => {
      return request('GET', `${API}/v1/productGroup/searchListForSelect`, params, success)
    },
    //分页获取询价记录
    getClientSearchVinLong: (params, success) => {
      return request('GET', `${API}/v1/client/client/getClientSearchVinLong`, params, success)
    },
    //保存/修改询价单
    save: (params, success) => {
      return request('BODY', `${API}/v1/inquiry/inquirySheet/save`, params, success)
    },
    //删除询价单
    deleteByIds: (params, success) => {
      return request('BODY', `${API}/v1/inquiry/inquirySheet/deleteByIds`, params, success)
    },
    // 根据询价单 询价产品组ID 查询规格信息表
    getByIdOld: (params, success) => {
      return request('GET', `${API}/v1/inquiry/inquirySheet/getById/${params}`, params, success)
    },
    // 根据询价单 询价产品组ID 查询规格信息表
    getById: (params, success) => {
      return request('GET', `${API}/v1/inquiry/inquirySheet/getDetailById/${params}`, params, success)
    },
    // 标记 员工是否已读(1:已读 0:未读)和维护客户最后一次浏览时间
    flagPersonnelHaveRead: (params, success, error) => {
      return request('GET', `${API}/v1/inquiry/inquirySheet/flagPersonnelHaveRead/${params}`, params, success, error)
    },
    // 更新询价单客户信息
    updateClientData: (params, success) => {
      return request('BODY', `${API}/v1/inquiry/inquirySheet/updateClientData`, params, success)
    },
    // 根据询价单 询价产品组ID 查询规格信息表
    getGoodsByIdAndItemId: (params, success) => {
      return request('BODY', `${API}/v1/inquiry/inquirySheet/getGoodsByIdAndItemId`, params, success)
    },
    // 根据询价单 询价产品组ID 查询规格信息表
    getGoodsListByItemId: (params, success) => {
      return request('BODY', `${API}/v1/inquiry/inquirySheet/getGoodsListByItemId`, params, success)
    },
    // 根据询价单 询价产品组ID 查询规格信息表
    getGoodsListByItemIdNew: (params, success) => {
      return request('BODY', `${API}/v1/inquiry/inquirySheet/getCountShopGoodsListByItemId`, params, success)
    },
    // 根据OE查询EPC图片
    getEpcImgByOeNumber: (params, success) => {
      return request('BODY', `${API}/v1/epc/getEpcImgByOeNumber`, params, success)
    },
    // 根据OE查询EPC图片
    getEpcImgDetail: (params, success, error) => {
      return request('BODY', `${API}/v1/epc/getEpcImgDetail`, params, success, error)
    },
    // 分页获取询价单列表
    inquirySheetSearchList: (params, success) => {
      return request('GET', `${API}/v1/inquiry/inquirySheet/searchList`, params, success)
    },
    // 分页获取采购单列表
    buyingSheetSearchList: (params, success) => {
      return request('GET', `${API}/v1/shop/buyingRequisition/searchList`, params, success)
    },
    // 根据VIN+想查的状态码 获取询价单列表
    getListByVin: (params, success) => {
      return request('BODY', `${API}/v1/inquiry/inquirySheet/getListByVin`, params, success)
    },
    // 根据VIN+想查的状态码 获取询价单列表
    deleteVinLog: (params, success) => {
      return request('BODY', `${API}/v1/client/clientSearchVinLog/deleteByClientId`, params, success)
    },
    // 根据VIN码 查询一级分类列表
    getGroupListByVinOrEpcId: (params, success) => {
      return request('BODY', `${API}/v1/epc/getMainGroup`, params, success)
    },
    // 根据一级分类 查询二级分类列表
    getSubgroup: (params, success) => {
      return request('BODY', `${API}/v1/epc/getSubGroup`, params, success)
    },
    // 选中了二级分类 查询三级分类列表
    getParts: (params, success) => {
      return request('BODY', `${API}/v1/epc/getParts`, params, success)
    },
  
    // 保存配件备注
    saveImgAndRemarkOld: (params, success) => {
      return request('BODY', `${API}/v1/shop/buyingRequisition/saveImgAndRemark`, params, success)
    },
  
    // 保存配件备注
    saveImgAndRemark: (params, success, error) => {
      return request('BODY', `${API}/v1/inquiry/inquirySheet/updateItemAttachmentAndRemark`, params, success, error)
    },
    // 保存配件备注
    getByInquiryItemId: (params, success) => {
      return request('GET', `${API}/v1/shop/buyingRequisition/getByInquiryItemId/${params}`, params, success)
    },
    // 询价单标记信息
    getInquirySheetItemGoodsList: (params, success, error) => {
      return request('BODY', `${API}/v1/inquiry/inquirySheet/getInquirySheetItemGoodsList`, params, success, error)
    },
    // 保养件
    getOrderQuicklyList: (params, success, error) => {
      return request('BODY', `${API}/v1/inquiry/inquirySheet/getOrderQuicklyList`, params, success, error)
    },
    // 保养件
    searchListForSelectOld: (params, success, error) => {
      return request('GET', `${API}/v1/accessories/productGroup/searchListForSelect`, params, success, error)
    },
    // 保养件
    searchListForSelect: (params, success, error) => {
      return request('GET', `${API}/v1/productGroup/searchListForSelect`, params, success, error)
    },
    // 分享保存
    quotedPriceShareSave: (params, success, error) => {
      return request('BODY', `${API}/v1/inquiry/quotedPriceShare/save`, params, success, error)
    },
    // 快速下单
    quotedPriceShareGetById: (params, success, error) => {
      return request('GET', `${API}/v1/inquiry/quotedPriceShare/getById/${params}`, params, success, error)
    },
    // 整单重询
    createByCopy: (params, success, error) => {
      return request('GET', `${API}/v1/inquiry/inquirySheet//createByCopy/${params}`, params, success, error)
    },
    // 标准名称分组
    productGroupLevelSearch: (params, success, error) => {
      return request('GET', `${API}/v1/accessories/productGroupLevel/searchList`, params, success, error)
    },
    // 三级标准名称分组
    productGroupSearch: (params, success, error) => {
      return request('GET', `${API}/v1/accessories/productGroup/searchList`, params, success, error)
    },
    // 根据VIN校验
    checkoutVin: (params, success, error) => {
      return request('BODY', `${API}/v1/inquiry/inquirySheet/checkoutVin`, params, success, error)
    },
    // 查询品牌件号列表
    getItemCountList: (params, success, error) => {
      return request('BODY', `${API}/v1/accessories/oeToCarItemCount/getItemCountList`, params, success, error)
    },
  };

  // 车型相关
export const SearchFetch = {
    // 汽车品牌搜索
    getAcBrandList: (params, success) => {
      return request('GET', `${API}/v1/accessories/accessoriesBrand/getAcBrandList`, params, success);
    },
    // 汽车热门品牌
    getHotAcBrandList: (params, success) => {
      return request('GET', `${API}/v1/accessories/accessoriesBrand/noAuth/getHotAcBrandList`, params, success);
    },
    // 汽车车系搜索
    getAcVersionListByQuery: (params, success) => {
      return request('GET', `${API}/v1/accessories/accessoriesVersion/noAuth/getAcVersionListByQuery`, params, success);
    },
    // 汽车车型搜索
    getAcTypeList: (params, success) => {
      return request('GET', `${API}/v1/accessories/accessoriesType/noAuth/getAcTypeList`, params, success);
    },
    searchCarParc: (params, success) => {
      return request('GET', `${API}/v1/carparc/searchList`, params, success);
    },
    //根据OE码+车品牌 查询OE码关联的对应品牌下的车版本列表
    searchCarListByGoodsId: (params, success) => {
      return request('GET', `${API}/v1/accessories/goodsOeType/searchList`, params, success);
    },
    //根据商品ID+OE码 查询关联车型品牌列表
    getAcBrandListByGoodsId: (params, success) => {
      return request('BODY', `${API}/v1/accessories/goodsOeType/noAuth/getAcBrandListByGoodsId`, params, success);
    },
    // 根据OE码+车品牌 查询OE码关联的对应品牌下的车系列表
    getAcVersionListByGoodsIdAndAcBrandId: (params, success) => {
      return request('BODY', `${API}/v1/accessories/goodsOeType/noAuth/getAcVersionListByGoodsIdAndAcBrandId`, params, success);
    },
    //根据OE码+车品牌 查询OE码关联的对应品牌下的车版本列表
    getAcCarListByGoodsIdAndAcBrandAndAcVersion: (params, success) => {
      return request('BODY', `${API}/v1/accessories/goodsOeType/noAuth/getAcCarListByGoodsIdAndAcBrandAndAcVersion`, params, success);
    },
  };
  
  
